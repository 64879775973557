import getNotifier from '@/utils/getNotifier'
import { CHART_COUNT_STATUS } from '@/modules/dashboards/components/chart/options/constants'
import { getTimeZone } from '@/utils/onlineUtils'
import { camelize } from 'humps'
import isEqual from '@/utils/isEqual'

import {
  ACTION_AGENT_PAUSE,
  ACTION_AGENT_LOGOUT,
  ACTION_AGENT_PENALTY,
  ACTION_AGENT_UNPAUSE
} from './constants'

const getTokenJWT = window.getTokenJWT
const api = window.api

const notifier = getNotifier()
const CLEAR_ONLINE = 'ONLINE_CLEAR'
const SET_SUMMARIES = 'SET_SUMMARIES'
const REFRESH_SUMMARY_PARAMS = 'ONLINE_REFRESH_SUMMARY_PARAMS'

const MUTATION_AGENT_PERMISSIONS = 'MUTATION_AGENT_PERMISSIONS'
const MUTATION_AGENT_PERMISSIONS_SAVED = 'MUTATION_AGENT_PERMISSIONS_SAVED'
const MUTATION_AGENT_PERMISSIONS_LOADED = 'MUTATION_AGENT_PERMISSIONS_LOADED'
const isLogin = (event) => event === 'QUEUEMEMBER_LOGIN'
const isLogoff = (event) => event === 'QUEUEMEMBER_LOGOFF'

const getInitialState = () => ({
  calls: {},
  agents: {},
  queues: {},
  extensions: {},
  queueStatus: '',
  versionVP: '',

  summary: {
    summaries: [],
    loading: true,
    filter: {
      queues: [],
      periodSecs: 3600,
      periodStart: 0,
      periodEnd: 0,
      timezone: getTimeZone(),
      chartType: CHART_COUNT_STATUS
    }
  },

  listGraphs: {},
  loadingGraphs: true,

  permissions: {
    agents: {},
    loading: false
  }
})

let intervalEvents = null

export default {
  state: getInitialState(),

  actions: {
    startListenEventsPbx ({ commit }, params) {
      const notifications = []

      const onInitialState = (notification) => notifications.push({ action: 'RESET_ONLINE', notification: sanitize(notification) })
      const callRemove = (notification) => notifications.push({ action: 'CALL_REMOVE', notification: sanitize(notification) })
      const callUpdate = (notification) => notifications.push({ action: 'CALL_UPDATE', notification: sanitize(notification) })
      const agentUpdate = (notification) => notifications.push({ action: 'AGENT_UPDATE', notification: sanitize(notification) })
      const onChangeset = (notification) => notifications.push({ action: 'QUEUE_PROPERTIES', notification: sanitize(notification) })
      const extensionUpdate = (notification) => notifications.push({ action: 'EXTENSIONS_UPDATE', notification: sanitize(notification) })
      const onDialerStatus = (notification) => notifications.push({ action: 'QUEUE_UPDATE', notification: sanitize(notification) })

      if (intervalEvents) clearInterval(intervalEvents)

      intervalEvents = setInterval(() => {
        if (notifications.length) {
          commit('SET_EVENTS', notifications.splice(0, notifications.length))
        }
      }, 350)

      const listenEvents = {
        onInitialState,
        onChangeset,
        onDialerStatus,

        onCallEnd: callRemove,
        onCallStart: callUpdate,
        onCallReject: callUpdate,
        onCallAttempt: callUpdate,
        onCallConnect: callUpdate,
        onRingCancel: callUpdate,

        onQueueJoin: callUpdate,
        onQueueAbandon: callRemove,

        onDialerStart: callUpdate,
        onDialerConnect: callUpdate,
        onDialerEnd: callRemove,
        extensionsUpdate: extensionUpdate,

        onAgentLogin: agentUpdate,
        onAgentPause: agentUpdate,
        onAgentLogoff: agentUpdate,
        onAgentUnpause: agentUpdate,
        onAgentPenalty: agentUpdate,

        onIvrHangup: callRemove
      }

      listenEvents.onDisconnected = () => {
        commit(CLEAR_ONLINE)
        notifier.unregister()
        setTimeout(() => connectSocket(), 3000)
      }

      const connectSocket = () => {
        notifier.onEvents(getTokenJWT, { queues: params.queues }, listenEvents)
      }

      connectSocket()
    },

    stopListenEventsPbx ({ commit }) {
      notifier.close()
      commit(CLEAR_ONLINE)
    },

    refreshSummaryChart: ({ commit }, params) => {
      commit(REFRESH_SUMMARY_PARAMS, params)
    },

    [ACTION_AGENT_LOGOUT]: async (_, agents) => {
      const logoutAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/logout`).catch((error) => {
          if (error.response.status === 400) return
          wait(500).then(() => logoutAgent(agentId, --times))
        })
      }

      await Promise.all(agents.map((agentId) => logoutAgent(agentId, 7)))
    },

    [ACTION_AGENT_PAUSE]: (_, { agents, reason }) => {
      const pauseAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/pause`, { body: { reasonId: reason } }).catch(() => wait(500).then(() => pauseAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => pauseAgent(agentId, 7)))
    },

    [ACTION_AGENT_UNPAUSE]: (_, agents) => {
      const unpauseAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/unpause`).catch(() => wait(500).then(() => unpauseAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => unpauseAgent(agentId, 7)))
    },

    [ACTION_AGENT_PENALTY]: (_, { agents, queue, penalty }) => {
      const penaltyAgent = (agentId, times) => {
        if (times <= 0) return
        return api.post(`/agent/${agentId}/penalty`, { body: { queueId: queue, penalty } }).catch(() => wait(500).then(() => penaltyAgent(agentId, --times)))
      }

      Promise.all(agents.map((agentId) => penaltyAgent(agentId, 7)))
    }
  },

  mutations: {
    SET_EVENTS: (state, events) => {
      const cState = {}
      cState.calls = structuredClone(state.calls || {})
      cState.agents = structuredClone(state.agents || {})
      cState.queues = structuredClone(state.queues || {})
      cState.versionVP = structuredClone(state.versionVP || '')
      cState.extensions = structuredClone(state.extensions || {})

      for (const { action, notification } of events) {
        switch (action) {
          case 'RESET_ONLINE':
            applyResetOnline(cState, notification)
            break

          case 'AGENT_UPDATE':
            applyAgentUpdate(cState, notification)
            break

          case 'CALL_UPDATE':
            applyCallUpdate(cState, notification)
            break

          case 'CALL_REMOVE':
            applyCallRemove(cState, notification)
            break

          case 'QUEUE_UPDATE':
            applyQueueUpdate(cState, notification)
            break

          case 'EXTENSIONS_UPDATE':
            applyExtensionUpdate(cState, notification)
            break

          case 'QUEUE_PROPERTIES':
            applyQueueProperties(cState, notification)
            break

          default:
            break
        }
      }

      if (isEqual(state.calls, cState.calls)) delete cState.calls
      if (isEqual(state.agents, cState.agents)) delete cState.agents
      if (isEqual(state.queues, cState.queues)) delete cState.queues
      if (isEqual(state.versionVP, cState.versionVP)) delete cState.versionVP
      if (isEqual(state.extensions, cState.extensions)) delete cState.extensions

      if (Object.keys(cState).length) Object.assign(state, cState)
    },

    [CLEAR_ONLINE]: (state) => {
      Object.assign(state, getInitialState())
    },

    [SET_SUMMARIES]: (state, { summaries }) => {
      state.summary = {
        ...state.summary,
        summaries,
        loading: false
      }
    },

    [REFRESH_SUMMARY_PARAMS]: (state, params) => {
      const filter = state?.summary?.filter ? { ...state.summary.filter } : {}

      if (params?.queues) filter.queues = params.queues
      if (params?.timezone) filter.timezone = params.timezone
      if (params?.chartType) filter.chartType = params.chartType
      if (params?.periodEnd) filter.periodEnd = params.periodEnd
      if (params?.periodSecs) filter.periodSecs = params.periodSecs
      if (params?.periodStart) filter.periodStart = params.periodStart

      state.summary = { ...state.summary, filter, loading: true }
    },

    [MUTATION_AGENT_PERMISSIONS]: (state, clear = true) => {
      state.permissions = {
        salved: false,
        loading: true,
        agents: clear ? {} : { ...state.permissions.agents }
      }
    },

    [MUTATION_AGENT_PERMISSIONS_LOADED]: (state, agents) => {
      state.permissions = {
        salved: false,
        loading: false,
        agents: reducePerms(agents)
      }
    },

    [MUTATION_AGENT_PERMISSIONS_SAVED]: (state, agents) => {
      state.permissions = {
        salved: true,
        loading: false,
        agents: reducePerms(agents)
      }
    }
  },

  middlewares: {
    [REFRESH_SUMMARY_PARAMS]: ({ commit }, { payload }, { dashboardStore }) => {
      const body = { ...dashboardStore.summary.filter, ...payload }
      if (!body?.queues?.length) return

      const loadSummary = (times = 7) => {
        if (times <= 0) return null
        return api.post('/cc/summaries/reports', { body }).catch(() => wait(1000).then(() => loadSummary(--times)))
      }

      loadSummary().then(({ data: { rows } }) => {
        commit(SET_SUMMARIES, { summaries: rows })
      })
    }
  }
}

const getQueueUpdated = (state, queueId, data) => {
  const notification = { ...data }
  const queue = Object.assign({ id: queueId, name: queueId, agents: [], calls: [] }, state.queues[queueId])

  const calls = Array.isArray(data.calls || queue.calls) ? data.calls || queue.calls : []
  const agents = Array.isArray(data.agents || queue.agents) ? data.agents || queue.agents : []

  const dialerMaxTrunks = Number(data.dialerMaxTrunks ?? queue.dialerMaxTrunks)
  const dialerAvgDials = Number(data.dialerAvgDials ?? queue.dialerAvgDials)

  const dialerCountContacts = Number(data.dialerCountContacts ?? queue.dialerCountContacts)
  const dialerCountFiltered = Number(data.dialerCountFiltered ?? queue.dialerCountFiltered)

  const dialerCountFailure = Number(data.dialerCountFailure ?? queue.dialerCountFailure)
  const dialerCountAbandons = Number(data.dialerCountAbandons ?? queue.dialerCountAbandons)
  const dialerCountCompleted = Number(data.dialerCountCompleted ?? queue.dialerCountCompleted)

  const summary = mergeData(queue.summary, notification.summary)

  return Object.assign(queue, {
    ...notification,
    agents,
    calls,
    summary,
    dialerMaxTrunks,
    dialerAvgDials,

    dialerCountContacts,
    dialerCountFiltered,

    dialerCountFailure,
    dialerCountAbandons,
    dialerCountCompleted
  })
}

const getAgentUpdated = (state, agentId, agentNotification) => {
  const newAgentData = structuredClone(agentNotification)
  const oldAgentData = state.agents[agentId] || {}
  const calls = Array.isArray(oldAgentData.calls) ? [...oldAgentData.calls] : []
  const memberships = mergeData(oldAgentData.memberships, newAgentData.memberships)
  const summaries = mergeData(oldAgentData.summaries, newAgentData.summary)
  const summary = mergeData(oldAgentData.summary, summaries[''])
  return { ...oldAgentData, ...newAgentData, summary, summaries, calls, memberships }
}

const isObj = (val) => !isEmpty(val) && !Array.isArray(val) && typeof val === 'object'
const isEmpty = (obj) => [null, undefined, ''].includes(obj) || JSON.stringify(obj) === '{}'
const wait = (ms = 500) => new Promise((resolve) => setTimeout(resolve, ms))

const mergeData = (oldObj, newObj) => {
  if (isEmpty(newObj) && isEmpty(oldObj)) return {}
  if (isEmpty(newObj)) return oldObj
  if (isEmpty(oldObj)) return newObj

  const obj = { ...oldObj }

  for (const key in newObj) {
    if (isObj(newObj[key]) && !isObj(oldObj[key])) {
      obj[key] = newObj[key]
      continue
    }

    if (isObj(newObj[key]) && isObj(oldObj[key])) {
      obj[key] = mergeData(oldObj[key], newObj[key])
      continue
    }

    if (!isObj(newObj[key]) && !isEmpty(newObj[key])) {
      obj[key] = newObj[key]
      continue
    }
  }

  return obj
}

const defaultPerms = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false }

const reducePerms = (agents) => (
  agents.reduce((group, { id, dialPermissions }) => ({
    ...group,
    [id]: dialPermissions.reduce((groupPerm, { callTypeId }) => ({
      ...groupPerm, [callTypeId]: true
    }), defaultPerms)
  }), {})
)

const prepareProperties = (val) => {
  if (isEmpty(val)) return {}
  if (Array.isArray(val)) return val
  if (isObj(val)) return Object.keys(val).reduce((obj, key) => ({ ...obj, [camelize(key)]: String(val[key]) }), {})
  return val
}

const sanitize = (event) => {
  const hasAgent = event?.agent?.id
  const hasCallAgent = event?.call?.agentId
  const hasEventAgent = event?.event?.agent
  const hasSummaries = event?.agent?.summaries
  const hasMemberships = event?.agent?.memberships

  if (hasAgent) event.agent.id = String(event.agent.id).padStart(4, '0')
  if (hasCallAgent) event.call.agentId = String(event.call.agentId).padStart(4, '0')
  if (hasEventAgent) event.event.agent = String(event.event.agent).padStart(4, '0')

  if (hasMemberships) {
    Object.keys(event.agent.memberships).forEach((queueId) => {
      if (event.agent.memberships[queueId]?.agentId) {
        event.agent.memberships[queueId].agentId = String(event.agent.memberships[queueId].agentId).padStart(4, '0')
      }
    })
  }

  if (hasSummaries) {
    Object.keys(event.agent.summaries).forEach((queueId) => {
      if (event.agent.summaries[queueId]?.agentId) {
        event.agent.summaries[queueId].agentId = String(event.agent.summaries[queueId].agentId).padStart(4, '0')
      }
    })
  }

  return event
}

const applyResetOnline = (state, notification) => {
  const calls = structuredClone(notification.calls)
  const agents = structuredClone(notification.agents)
  const queues = structuredClone(notification.queues)
  const extensions = structuredClone(notification.extensions)
  const { versionVP } = notification
  const listcalls = Object.values(calls)

  Object.values(agents).forEach((agent) => {
    agent.calls = listcalls
      .filter(({ agentId }) => agentId === agent.id)
      .map(({ id }) => id)
  })

  Object.values(queues).forEach((queue) => {
    queue.calls = listcalls.filter(({ queueId }) => queueId === queue.id).map(({ id }) => id)
  })

  Object.assign(state, { calls, agents, queues, extensions, versionVP })
}

const applyAgentUpdate = (state, notification) => {
  const { queue: queueId, event: eventName } = notification.event
  const agentId = notification.event.agent
  const agent = getAgentUpdated(state, agentId, notification.agent)
  if (isLogoff(eventName)) delete agent.memberships[queueId]

  state.agents[agentId] = agent

  const tryAddAgentInQueue = isLogin(eventName)
  const tryRemoveAgentInQueue = isLogoff(eventName)
  if (!tryAddAgentInQueue && !tryRemoveAgentInQueue) return

  const queue = structuredClone(state.queues[queueId] || {})
  if (!queue.id) queue.id = queueId
  if (!queue.name) queue.name = queueId
  if (!queue.summary) queue.summary = {}
  if (!Array.isArray(queue.calls)) queue.calls = []
  if (!Array.isArray(queue.agents)) queue.agents = []

  const hasAgent = queue.agents.includes(agentId)
  if (tryAddAgentInQueue && !hasAgent) queue.agents.push(agentId)
  if (tryRemoveAgentInQueue && hasAgent) queue.agents.splice(queue.agents.indexOf(agentId), 1)

  state.queues[queueId] = queue
}

const applyCallUpdate = (state, notification) => {
  const { queue: queueId, callId, event } = notification.event
  const agentId = notification.event.agent ? notification.event.agent : null

  const call = notification.call ? Object.assign({}, notification.call) : { id: callId }
  const contact = notification.contact ? Object.assign({}, notification.contact) : null
  if (contact) {
    call.contactId = contact.contactId
    call.contactName = contact.contactName
    call.contact = contact
  }

  state.calls = Object.assign({}, state.calls, { [callId]: call })

  if (queueId) {
    const queue = getQueueUpdated(state, queueId, notification.queue)
    if (!queue.calls.includes(callId)) queue.calls.push(callId)
    if (agentId && !queue.agents.includes(agentId)) queue.agents.push(agentId)
    state.queues[queueId] = queue
  }

  if (!agentId) return

  const agent = getAgentUpdated(state, agentId, notification.agent)
  if (!agent.calls.includes(callId)) agent.calls.push(callId)
  if (['QUEUEMEMBER_CALLREJECT', 'QUEUEMEMBER_RINGCANCELED'].includes(event)) agent.calls = agent.calls.filter((call) => call !== callId)
  state.agents[agentId] = agent
}

const applyCallRemove = (state, notification) => {
  let { callId, queue: queueId, agent: agentId } = notification.event
  if (!agentId) agentId = notification?.agent?.id ?? null
  const ignoreEventsAgent = ['QUEUECALLER_ABANDON']

  if (agentId && !ignoreEventsAgent.includes(notification.event.event)) {
    const agent = getAgentUpdated(state, agentId, notification.agent)
    if (agent.calls.includes(callId)) agent.calls.splice(agent.calls.indexOf(callId), 1)
    state.agents[agentId] = agent
  }

  if (queueId) {
    const queue = getQueueUpdated(state, queueId, notification.queue)
    if (queue.calls.includes(callId)) queue.calls.splice(queue.calls.indexOf(callId), 1)
    state.queues[queueId] = queue
  }

  const calls = structuredClone(state.calls)
  delete calls[callId]
  state.calls = calls
}

const applyQueueUpdate = (state, notification) => {
  const queueId = notification?.event?.queue
  if (!queueId) return

  const { contacts, contactCalls, contactPrepareds, contactRequesteds, ...queueProps } = notification.event.state

  const queue = Object.assign({}, state.queues[queueId] || {})
  queue.id = queueId
  queue.contacts = Object.assign({}, contacts || {})
  queue.contactCalls = Object.assign({}, contactCalls || {})
  queue.contactPrepareds = Array.isArray(contactPrepareds) ? contactPrepareds : []
  queue.contactRequesteds = Array.isArray(contactRequesteds) ? contactRequesteds : []

  if (Object.keys(queueProps).length) Object.assign(queue, queueProps)
  state.queues[queueId] = queue
}

const applyExtensionUpdate = (state, notification) => {
  const { extensions } = notification
  const { action } = notification.event
  if (action === 'RESET') state.extensions = {}
  const extensionIds = Array.isArray(notification.event.extensionsId) ? notification.event.extensionsId : []

  for (const id of extensionIds) {
    state.extensions[id] = structuredClone(extensions[id])
  }
}

const applyQueueProperties = (state, notification) => {
  const { queue, event } = notification
  if (!queue) return

  const queueId = event.queue || queue?.id
  if (!queueId) return

  const { dialerSpeed, lcrProfileId, dialerMaxTrunks, weight } = prepareProperties(queue.properties)
  state.queues[queueId] = Object.assign({}, state.queues[queueId] || {}, { dialerSpeed, lcrProfileId, dialerMaxTrunks, weight })
}
