import {
  ROUTE_SWAGGER_API_DOCS,
  ROUTE_SWAGGER_API_RESULTS,
  ROUTE_SWAGGER_API_CONTACTS,
  ROUTE_SWAGGER_API_RPC_AGENTS,
  ROUTE_SWAGGER_API_BOTS_MESSAGE,
  ROUTE_SWAGGER_API_BILLING_CALLS
} from './constants'

const SwaggerList = () => import(/* webpackChunkName: "api-docs" */ './views/SwaggerList')
const SwaggerView = () => import(/* webpackChunkName: "api-docs-view" */ './views/SwaggerView')

const baseBreadcrumbs = (child) => {
  const breadcrumbs = [{ label: 'API', active: !child }]
  if (child) breadcrumbs.push({ ...child, active: true })
  return breadcrumbs
}

export default [{
  path: '/v1/api-docs',
  name: ROUTE_SWAGGER_API_DOCS,
  meta: { breadcrumb: baseBreadcrumbs() },
  component: SwaggerList
}, {
  path: '/v1/api-docs/contatos-discador',
  name: ROUTE_SWAGGER_API_CONTACTS,
  meta: { breadcrumb: baseBreadcrumbs({ label: 'Alimentação de Contatos/Discador' }) },
  component: SwaggerView
}, {
  path: '/v1/api-docs/resultados-atendimentos',
  name: ROUTE_SWAGGER_API_RESULTS,
  meta: { breadcrumb: baseBreadcrumbs({ label: 'Atendimentos, Perfilamentos e Sumários' }) },
  component: SwaggerView
}, {
  path: '/v1/api-docs/agentes-pabx',
  name: ROUTE_SWAGGER_API_RPC_AGENTS,
  meta: { breadcrumb: baseBreadcrumbs({ label: 'Controle dos agentes do PABX' }) },
  component: SwaggerView
}, {
  path: '/v1/api-docs/bots-messages',
  name: ROUTE_SWAGGER_API_BOTS_MESSAGE,
  meta: { breadcrumb: baseBreadcrumbs({ label: 'Envio de mensagens em massa' }) },
  component: SwaggerView
}, {
  path: '/v1/api-docs/tarifador',
  name: ROUTE_SWAGGER_API_BILLING_CALLS,
  meta: { breadcrumb: baseBreadcrumbs({ label: 'Tarifador' }) },
  component: SwaggerView

}
]
