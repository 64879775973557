const state = {
  adminRows: [{ id: 'allowListen', name: 'Escuta de Chamadas' },
    { id: 'adminUras', name: 'Administrar URAs' },
    { id: 'adminEntryNumbers', name: 'Administrar Números de Entradas' },
    { id: 'adminAgents', name: 'Administrar Agentes' },
    { id: 'adminUsers', name: 'Administrar Usuários' },
    { id: 'adminBanners', name: 'Administrar Banners' },
    { id: 'adminQueues', name: 'Administrar Filas' },
    { id: 'adminRoutes', name: 'Administrar Rotas' },
    { id: 'adminSounds', name: 'Administrar Áudios' },
    { id: 'adminTrunkings', name: 'Administrar Troncos' },
    { id: 'adminExtensions', name: 'Administrar Ramais' },
    { id: 'adminBots', name: 'Administrar Brokers de Atendimento' },
    { id: 'adminBillingCalls', name: 'Administrar Planos e Tarifas' },
    { id: 'adminPerfilator', name: 'Administrar Perfiladores' },
    { id: 'adminPauseReasons', name: 'Administrar Pausas' },
    { id: 'adminTokens', name: 'Administrar Tokens' },
    { id: 'adminTranscriptions', name: 'Administrar Transcrições' }
  ],

  accessRows: [
    { id: 'dashboards', name: 'Dashboards' },
    { id: 'calls', name: 'Chamadas' },
    { id: 'chats', name: 'Chats' },
    { id: 'emails', name: 'Emails' },
    { id: 'agents', name: 'Agentes' },
    { id: 'admin', name: 'Administração' },
    { id: 'billing', name: 'Tarifador' }
  ]
}

const getters = {
  adminPermissions: (state) => state.adminRows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {}),
  accessPermissions: (state) => state.accessRows.reduce((grp, item) => ({ ...grp, [item.id]: item.name }), {})
}

export default {
  state,
  getters,
  namespaced: true
}
